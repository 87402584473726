import React, { useState, useEffect } from "react";
import request from "umi-request";
import logo from "./logo.svg";
import "./App.css";
const CryptoJS = require("crypto-js");
function App() {
  const [data, setData] = useState(null);
  const [title, setTitle] = useState("");
  const [type, setType] = useState(0);
  const [project, setProject] = useState("");
  const [err, setErr] = useState("");
  useEffect(() => {
    console.log(window.location.search);
    if(window.location.search.slice(1, 5)){
          let tp = window.location.search.slice(1, 5);
        if (tp === "file") {
          setType(2);
        }
        if (tp === "data") {
          setType(1);
        }
        let boxdata = window.location.search.slice(
          6,
          window.location.search.length
        );
        boxdata = decryptword(boxdata);
        boxdata = JSON.parse(boxdata);
        if (boxdata.title) {
          setData(boxdata);
          setTitle(boxdata.title);
          if (boxdata.project) {
            setProject(boxdata.project);
          }
          setTitle(boxdata.title);
    }

    }
  }, []);
  // 解密可见字符
  const decryptword = (word, key = "65be2d673115e6ca") => {
    let keyWA = CryptoJS.enc.Utf8.parse(key); // Latin1 w8m31+Yy/Nw6thPsMpO5fg==
    let decrypt = CryptoJS.AES.decrypt(word, keyWA, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.ZeroPadding,
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  };
  const preview = () => {
    request
      .get("http://www.comasql.com/upload/download", {
        params: {
          random: data.random,
          username: data.username,
          state: data.state,
          version: data.version,
        },
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        const blob = new Blob([res], { type: "application/pdf;chartset=UTF8" });
        const URL = window.URL || window.webkitURL;
        const href = URL.createObjectURL(blob);
        window.open(href);
      })
      .catch((error) => {
        console.log(error);
        setErr("文件出错");
        console.log("error");
      });
  };

  const download = () => {
    request
      .get("http://www.comasql.com/upload/download", {
        params: {
          random: data.random,
          username: data.username,
          state: data.state,
          version: data.version,
        },
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        const blob = new Blob([res], { type: "application/pdf;chartset=UTF8" });
        const a = document.createElement("a");
        const URL = window.URL || window.webkitURL;
        const href = URL.createObjectURL(blob);
        a.href = href;
        a.download = `${data.title}-Ver${data.version}-规格书.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log(error);
        setErr("文件出错");
        console.log("error");
      });
  };

  const filepreview = () => {
    request
      .get("http://www.comasql.com/upload/filedownload", {
        params: {
          random: data.random,
          username: data.username,
          state: data.state,
          version: data.version,
        },
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        const blob = new Blob([res], { type: "application/pdf;chartset=UTF8" });
        const URL = window.URL || window.webkitURL;
        const href = URL.createObjectURL(blob);
        window.open(href);
      })
      .catch((error) => {
        console.log(error);
        setErr("文件出错");
        console.log("error");
      });
  };

  const filedownload = () => {
    request
      .get("http://www.comasql.com/upload/filedownload", {
        params: {
          random: data.random,
          username: data.username,
          state: data.state,
          version: data.version,
        },
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        const blob = new Blob([res], { type: "application/pdf;chartset=UTF8" });
        const a = document.createElement("a");
        const URL = window.URL || window.webkitURL;
        const href = URL.createObjectURL(blob);
        a.href = href;
        a.download = `${data.project}-${data.title}-Ver${data.version}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log(error);
        setErr("文件出错");
        console.log("error");
      });
  };

  return (
    <div>
      {type === 1 && (
        <div className="App">
          <div className="title">{title}</div>
          <button onClick={preview} className="preview">
            预览
          </button>
          <button onClick={download} className="download">
            下载
          </button>
          <div>{err}</div>
        </div>
      )}
      {type === 2 && (
        <div className="App">
          <div className="title">
            {project}
            {title}
          </div>
          <button onClick={filepreview} className="preview">
            预览
          </button>
          <button onClick={filedownload} className="download">
            下载
          </button>
          <div>{err}</div>
        </div>
      )}

      {type === 0 && (
          <div className="content">
            <div className="content-1">
             <div className="content-1-1">
               西马云官网
             </div>
            </div>
            <div className="content-2">
             <div className="content-2-1">
                 网站建设中
             </div>
            </div>
            <div className="content-3">
              <div className="content-3-1">
                   <a className="content-3-1-1" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备16017029号-4</a> ｜ 西马智能科技股份有限公司版权所有 COPYRIGHT 2023 COMAI
              </div>
              {/* <div className="content-3-2">
                   <a className="content-3-1-1" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备16017029号-4</a> ｜ 西马智能科技股份有限公司版权所有 COPYRIGHT 2023 COMAI
              </div> */}
            </div>
          </div>
      )}
      
    </div>
  );
}

export default App;
